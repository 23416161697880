/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flexcolumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flexcolumnalt {
  display: flex;
  flex-direction: column;
}
.center {
  margin: auto;
}
.App {
  background-color: #fcf8d9;
  color: #2c1c17;
}
.App nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  border: 6px groove #ecdf3a;
  padding: 0.5em;
  background-color: #808080;
  color: #fcf8d9;
}
.App nav .title-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App nav .title-bar img {
  width: 12em;
}
.App nav .nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
}
.App nav .nav-links a {
  padding: 0 0.5em;
  text-decoration: none;
  color: #2c1c17;
}
.App nav .nav-links a:hover {
  background-color: #fcf8d9;
}
.App .main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
.App .main-wrapper h1 {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding: 0 0 0.5em 0;
}
.App .main-wrapper h2 {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  padding: 0 0 0.5em 0;
}
.App .main-wrapper .links {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.App .main-wrapper .links a {
  color: #2c1c17;
  padding: 0.2em;
}
.App .main-wrapper .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0 0.5em 0;
}
.App .main-wrapper .content p {
  font-size: 1.2em;
  padding: 0.5em;
  max-width: 600px;
}
.App .main-wrapper .content .home-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .main-wrapper .content .home-header #heading {
  width: 90%;
}
.App .main-wrapper .content .home-header #questions {
  width: 90%;
  margin-top: 1em;
}
.App .main-wrapper .content .about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .main-wrapper .content .about-wrapper .employee-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .main-wrapper .content .about-wrapper .employee-card .image {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3em;
}
.App .main-wrapper .content .about-wrapper .employee-card .image img {
  width: 8em;
  border: 2px groove #3e8e95;
  border-radius: 50%;
}
.App .main-wrapper .content .about-wrapper .employee-card .image .position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .main-wrapper .content .about-wrapper .employee-card .image .position h4 {
  font-weight: bold;
  padding: 0.5em;
}
.App .main-wrapper .content .about-wrapper .employee-card .image .position p {
  padding: 0.3em;
}
.App .main-wrapper .content .about-wrapper .employee-card .about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
}
.App .main-wrapper .content .section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .main-wrapper .content .section h3 {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 1em;
}
.App .main-wrapper .content .section .title {
  border-bottom: 4px groove #a6a267;
}
.App .main-wrapper .content .section .packages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .main-wrapper .content .section .packages .the-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
}
.App .main-wrapper .content .section .packages .the-items .item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px groove #2c1c17;
  padding: 0.2em;
  margin: 0.5em;
  width: 230px;
  min-height: 275px;
}
.App .main-wrapper .content .section .packages .the-items .item-card h3 {
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 0.2em;
}
.App .main-wrapper .content .section .packages .the-items .item-card p {
  text-align: center;
}
.App .main-wrapper .content .section .packages .the-items .item-card .item-info {
  font-style: italic;
}
.App .main-wrapper .content .section .packages .the-items .item-card .item-info p {
  text-align: left;
}
.App .forms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #a6a267;
  border: 4px groove #a6a267;
  margin-bottom: 3em;
  justify-content: center;
}
.App .footer .icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .footer .icons img {
  width: 2em;
  margin: 0.5em;
}
.App .footer .icons .social {
  width: 1.4em;
  border-radius: 50%;
}
.App .footer .madeby {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App .footer .madeby h4 {
  font-weight: 200;
  color: #fcf8d9;
  padding: 0.5em;
}
@media only screen and (min-width: 400px) {
  .App nav .title-bar img {
    width: 15em;
  }
  .App .main-wrapper .content .section .packages .the-items .item-card {
    width: 330px;
  }
}
@media only screen and (min-width: 530px) {
  .App nav .title-bar img {
    width: 18em;
  }
  .App .main-wrapper .content .section .packages .the-items .item-card {
    width: 500px;
  }
  .App .footer .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1em;
  }
}
@media only screen and (min-width: 730px) {
  .App nav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .App nav .nav-links a {
    padding: 1em;
  }
  .App .main-wrapper {
    min-height: 400px;
    justify-content: flex-start;
  }
  .App .main-wrapper h1 {
    font-size: 2.5em;
  }
  .App .main-wrapper .content .section .packages .the-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .App .main-wrapper .content .section .packages .the-items .item-card {
    flex-wrap: wrap;
    width: 400px;
    align-self: flex-start;
  }
  .App .footer .icons {
    margin-right: 2em;
  }
  .App .footer .icons img {
    width: 3em;
    margin: 1em;
  }
  .App .footer .icons .social {
    width: 2em;
  }
}
@media only screen and (min-width: 850px) {
  .App .main-wrapper .content .home-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .App .main-wrapper .content .home-header #heading {
    width: 40%;
  }
  .App .main-wrapper .content .home-header #questions {
    width: 40%;
  }
}
